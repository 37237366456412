* {
  font-family: "Patrick Hand SC", cursive;
  font-weight: 400;
  font-style: normal;
  box-sizing: border-box;
}

body {
  background-color: #fffdee;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: min(3vw, 20px);
  width: 100%;
  padding: 10px;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(4, min(180px, 12vw));
  grid-gap: 20px;
  width: 75vw;
  max-width: 800px;
  height: auto;
  justify-content: center;
}

.note-button {
  outline: none;
}
