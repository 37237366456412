.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75vw;
  max-width: 800px;
}

.social-media-wrap {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.social-icon-link {
  color: #00c22d;
  font-size: min(5vw, 44px);
}
