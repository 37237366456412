.cube {
  width: 10vw; /* Original size */
  height: 10vw; /* Original size */
  max-width: 200px;
  max-height: 200px;
  position: relative;
  transform-style: preserve-3d; /* Ensure 3D transform is preserved */
  transform: rotateX(30deg) rotateY(45deg); /* Slightly above and turned */
  animation: spin 5s infinite linear; /* Only spin animation */
}

.face {
  position: absolute;
  width: 10vw; /* Original size */
  height: 10vw; /* Original size */
  max-width: 200px;
  max-height: 200px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.face img {
  width: 100%;
  height: 100%;
}

.front {
  transform: translateZ(min(5vw, 100px)); /* Original size */
}

.back {
  transform: rotateY(180deg) translateZ(min(5vw, 100px)); /* Original size */
}

.top {
  transform: rotateX(90deg) translateZ(min(5vw, 100px)); /* Original size */
}

.bottom {
  transform: rotateX(-90deg) translateZ(min(5vw, 100px)); /* Original size */
}

.left {
  transform: rotateY(-90deg) translateZ(min(5vw, 100px)); /* Original size */
}

.right {
  transform: rotateY(90deg) translateZ(min(5vw, 100px)); /* Original size */
}

@keyframes spin {
  0% {
    transform: rotateX(30deg) rotateY(45deg);
  }
  50% {
    transform: rotateX(390deg) rotateY(45deg); /* Rotate up */
  }
  100% {
    transform: rotateX(390deg) rotateY(405deg); /* Rotate over */
  }
}
