.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vw; /* Use 90% of the viewport width */
  max-width: 800px; /* Maximum width for alignment */
  box-sizing: border-box;
  margin: 2vw;
}

/* Hide the instruction box */
.message-svg-container {
  display: none; /* Hides the message box */
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Four equal columns */
  grid-template-rows: repeat(2, 1fr); /* Two rows */
  gap: 10px;
  width: 75vw; /* Match the width of the container */
  max-width: 800px; /* Ensure the grid does not exceed container width */
  box-sizing: border-box;
}

.note-button {
  width: 100%; /* Fills the grid cell */
  aspect-ratio: 1; /* Keeps buttons square */
  border: none;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  touch-action: manipulation;
  transition: transform 0.1s, box-shadow 0.1s; /* Smooth transition for the press effect */
}

.note-button:active,
.note-button.active {
  transform: scale(0.9); /* Slightly shrink the button when pressed */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

@media (max-width: 600px) {
  .container {
    width: 100vw; /* Use full viewport width on smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .button-grid {
    gap: 5px; /* Reduce gaps between buttons on smaller screens */
  }

  .note-button {
    aspect-ratio: 1; /* Keep buttons square */
  }
}
